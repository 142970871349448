import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/blog-layout.js";
import Fam from "./fam.jpg";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className="px-6 lg:px-40 my-12 flex flex-col items-center">
  <h2 className="text-4xl text-main-navy">
    {props.pageContext.frontmatter.title}
  </h2>
  <h4 className="text-xl text-gray-600 text-center max-w-4xl">
    Thanks for checking out my blog. It is very new, but I will be posting dev
    tips I've picked up on my journey to learning web development.
  </h4>
  {
        /* <img src={Fam} className="self-center rounded-md shadow-md my-4" /> */
      }
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      